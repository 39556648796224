import React, { useEffect, useRef, useState } from 'react';

import R from 'ramda';

import { ButtonVariants } from '~/shared/components/Button';
import {
  getSingleSkeletonPlaceholder,
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
  useSkeletonableState,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { formatDateRange } from '~/shared/helpers/date';

import {
  getBackendInputByValueKind,
  readSourceFieldFragment,
} from '~/entities/blueprintSourceFields';
import { CustomMilkingReportDetailedFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportDetailed.graphql';
import { useCalculateCustomMilkingReportMutation } from '~/entities/customMilkingReports/gql/mutations/calculateCustomMilkingReport.graphql';
import { useSetCustomMilkingReportSettingsMutation } from '~/entities/customMilkingReports/gql/mutations/setCustomMilkingReportSettings.graphql';

import {
  EditableReportCard,
  EditableReportCardStates,
} from '~/features/customReportsBuilder';

import { CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID } from '../../constants';
import {
  isCustomMilkingReportDataEmpty,
  mapCustomMilkingReportSettingsToForm,
} from '../../helpers';
import {
  CustomMilkingReportSettingsFormType,
  FiltersAndGroupingsModes,
} from '../../types';
import { CustomMilkingReportData } from '../CustomMilkingReportData';
import { CustomMilkingReportSettingsForm } from '../CustomMilkingReportSettingsForm';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to take settings from
   */
  customMilkingReport:
    | CustomMilkingReportDetailedFragment
    | SkeletonPlaceholder;
}

export const CustomMilkingReportCard: React.FC<Props> = ({
  className,
  customMilkingReport,
}) => {
  const { isLoading } = useSkeletonContext();

  const abortControllerRef = useRef<AbortController>();

  const [
    setCustomMilkingReportSettings,
    { loading: isSetCustomMilkingReportSettingsLoading, client },
  ] = useSetCustomMilkingReportSettingsMutation();

  const [
    calculateCustomMilkingReport,
    { reset: resetCalculateCustomMilkingReportMutation },
  ] = useCalculateCustomMilkingReportMutation();

  const initialReportData =
    customMilkingReport.calculatedReport ?? getSingleSkeletonPlaceholder();

  const isEmptyReport = isCustomMilkingReportDataEmpty(initialReportData);

  const initialSettings =
    customMilkingReport.settings ?? getSingleSkeletonPlaceholder();

  const initialSettingsForm =
    mapCustomMilkingReportSettingsToForm(initialSettings);

  // State for default data, cause we might have multiple calculations, but we don't mutate customMilkingReport
  const [defaultData, setDefaultData] = useState(initialReportData);

  const [cardState, setCardState] = useState(
    isEmptyReport
      ? EditableReportCardStates.update
      : EditableReportCardStates.view
  );

  const [requestedSettings, setRequestedSettings] =
    useState(initialSettingsForm);

  const [currentData, setCurrentData, setCurrentDataToSkeleton] =
    useSkeletonableState(initialReportData);

  // Update report data from skeleton, after we loaded launch result
  useEffect(() => {
    if (!isSkeletonPlaceholder(customMilkingReport)) {
      setCurrentData(initialReportData);
    }
  }, [customMilkingReport]);

  // Update custom report form values, after we loaded custom report
  useEffect(() => {
    if (!isSkeletonPlaceholder(customMilkingReport)) {
      setRequestedSettings(
        mapCustomMilkingReportSettingsToForm(initialSettings)
      );
      setDefaultData(initialReportData);
    }
  }, [customMilkingReport]);

  // Set updating state, if we loaded empty data
  useEffect(() => {
    if (isEmptyReport && !isLoading) {
      setCardState(EditableReportCardStates.update);
    }
  }, [isEmptyReport, isLoading]);

  const mapCustomReportSettingsFormToBackendInput = (
    settingsForm: CustomMilkingReportSettingsFormType
  ) => ({
    ...R.omit(['period', 'filters', 'grouping'], settingsForm),
    ...settingsForm.period,
    grouping:
      settingsForm.grouping &&
      (settingsForm.grouping.mode === FiltersAndGroupingsModes.actual
        ? {
            masterBlueprintSourceFieldID:
              settingsForm.grouping.masterBlueprintSourceFieldID,
          }
        : {
            kind: settingsForm.grouping.kind,
          }),
    filters: settingsForm.filters.map(filterConfig => {
      if (
        filterConfig.mode === FiltersAndGroupingsModes.actual &&
        filterConfig.actualFilter
      ) {
        const {
          masterBlueprintSourceFieldID,
          value: masterBlueprintSourceFieldValue,
        } = filterConfig.actualFilter;

        const selectedSourceField = readSourceFieldFragment(
          client,
          masterBlueprintSourceFieldID
        );

        return {
          actualFilter: {
            masterBlueprintSourceFieldID,
            value:
              selectedSourceField &&
              getBackendInputByValueKind(
                selectedSourceField.returnValueKind,
                masterBlueprintSourceFieldValue
              ),
          },
        };
      }

      return {
        historicFilter: {
          kind: filterConfig.historicFilter?.kind ?? null,
          value: filterConfig.historicFilter?.value ?? null,
        },
      };
    }),
  });

  return (
    <EditableReportCard
      {...{
        title: formatDateRange(initialSettings?.since, initialSettings.till),
        updatingTitle: 'Настройки графика и таблицы',
        className,
        cardState,
        settingsButtonProps: {
          variant: ButtonVariants.primary,
          children: 'Настройки',
        },
        onCardStateChange: newCardState => {
          // Cancel is pressed
          if (newCardState === EditableReportCardStates.view) {
            abortControllerRef.current?.abort();
            resetCalculateCustomMilkingReportMutation();
            setRequestedSettings(initialSettingsForm);
            setCurrentData(defaultData);
          }
          setCardState(newCardState);
        },
        submitButtonProps: {
          form: CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID,
          isLoading: isSetCustomMilkingReportSettingsLoading,
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(customMilkingReport) && (
            <CustomMilkingReportSettingsForm
              {...{
                customMilkingReport,
                onFormSettingsChange: settings => {
                  abortControllerRef.current?.abort();

                  abortControllerRef.current = new AbortController();

                  setCurrentDataToSkeleton();

                  calculateCustomMilkingReport({
                    variables: {
                      id: customMilkingReport.id,
                      settings:
                        mapCustomReportSettingsFormToBackendInput(settings),
                    },
                    context: {
                      fetchOptions: {
                        signal: abortControllerRef.current.signal,
                      },
                    },
                  })
                    .then(({ data }) => {
                      if (data?.calculateCustomMilkingReport.__typename) {
                        setRequestedSettings(settings);
                        setCurrentData(data.calculateCustomMilkingReport);
                      }
                    })
                    .catch(err => {
                      // Don't set empty state if we aborted the request with a new one
                      if (err?.cause?.name === 'AbortError') {
                        return;
                      }
                      setCurrentData({
                        __typename: 'CustomMilkingReportChartEmpty',
                      });
                    });
                },
                onSubmit: settingsForm => {
                  setCustomMilkingReportSettings({
                    variables: {
                      id: customMilkingReport.id,
                      input:
                        mapCustomReportSettingsFormToBackendInput(settingsForm),
                    },
                  })?.then(() => {
                    setDefaultData(currentData);
                    setCardState(EditableReportCardStates.view);
                  });
                },
              }}
            />
          ),
      }}
    >
      <Skeleton isLoading={isSkeletonPlaceholder(currentData)}>
        <CustomMilkingReportData
          {...{
            reportData: currentData,
            requestedSettings,
          }}
        />
      </Skeleton>
    </EditableReportCard>
  );
};

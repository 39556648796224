import React from 'react';

import clsx from 'clsx';

import { AsyncList, AsyncListProps } from '~/shared/components/AsyncList';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { isTmpId } from '~/shared/helpers/string';

import { useArkaNavigation } from '~/services/navigation';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';

import { BlueprintCard } from '../BlueprintCard';

interface Props extends Partial<AsyncListProps<BlueprintFragment>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprints to render
   */
  blueprints: BlueprintFragment[];
  /**
   * Called, when user clicked on a button to delete blueprint
   */
  onDelete?: (blueprintId: string) => void;
  /**
   * Called, when user wants to run a blueprint
   */
  onRun?: (blueprintId: string) => void;
}

export const BlueprintCardsList: React.FC<Props> = ({
  className,
  blueprints,
  onDelete,
  onRun,

  ...asyncProps
}) => {
  const { navigate, urlCompanyId } = useArkaNavigation();

  return (
    // TODO add skeletons
    <AsyncList<BlueprintFragment, false>
      {...{
        className: clsx(contentGridStyles.autoGridDynamicItems, className),
        wrapperTag: 'div',
        items: blueprints,
        noItemsMessage: (
          <Typography
            variant={TypographyVariants.bodySmall}
            tag="p"
            className="col-span-full"
          >
            По выбранным фильтрам не найдено карточек
          </Typography>
        ),
        renderItem: blueprint => (
          <BlueprintCard
            {...{
              key: blueprint.id,
              blueprint,
              onRun: () => {
                if (!isTmpId(blueprint.id)) {
                  navigate({
                    to: '/$companyId/user/blueprint/$blueprintId',
                    params: {
                      blueprintId: blueprint.id,
                      companyId: urlCompanyId,
                    },
                  });
                }

                onRun?.(blueprint.id);
              },
              onDelete: () => onDelete?.(blueprint.id),
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useApolloClient } from '@apollo/client';

import { CustomMilkingReportSettingsHistoricKind } from '@graphql-types';
import R from 'ramda';
import { match } from 'ts-pattern';

import { DateInputThemes } from '~/shared/components/DateInput';
import { InputThemes } from '~/shared/components/Input';
import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { Select, SelectThemes } from '~/shared/components/Select';

import { emptySwitchableFormFields } from '~/services/forms';
import { useArkaNavigation } from '~/services/navigation';

import {
  BlueprintSourceFieldAsyncSelect,
  readSourceFieldFragment,
  renderInputByValueKind,
} from '~/entities/blueprintSourceFields';
import { useDetailedCompany } from '~/entities/companies';
import { PenGroupAsyncSelect } from '~/entities/penGroups';

import { EditableArrayItemCard } from '~/features/customReportsBuilder';

import {
  CustomMilkingReportFilterFormType,
  CustomMilkingReportSettingsFormType,
  FiltersAndGroupingsModes,
} from '../../../../types';
import { useFiltersAndGroupingsModeItems } from '../../hooks/useFiltersAndGroupingsModeItems';
import { CustomMilkingReportSettingsHistoricKindSelect } from '../CustomMilkingReportSettingsHistoricKindSelect';
import { LactationGroupNumberSelect } from '../LactationGroupNumberSelect';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field name prefix to use for form fields in array
   */
  fieldPrefix: `filters.${number}.`;
  /**
   * Y axis field config to render
   */
  filterConfig: CustomMilkingReportFilterFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
}

export const FilterConfigArrayItemCard: React.FC<Props> = ({
  className,
  fieldPrefix,
  filterConfig,
  onDelete,
}) => {
  const client = useApolloClient();

  const formContext = useFormContext<CustomMilkingReportSettingsFormType>();

  const { selectedCompany } = useArkaNavigation();

  const { isDetailedCompanyLoading, companyDetailed } =
    useDetailedCompany(selectedCompany);

  const groupingModeItems = useFiltersAndGroupingsModeItems();

  const selectedFilterMode = filterConfig.mode;

  const isActualFilterMode =
    selectedFilterMode === FiltersAndGroupingsModes.actual;

  const commonHistoricFilterValueSelectProps = {
    label: 'Значение',
    isMulti: true,
    theme: SelectThemes.light,
  } as const;

  const selectedSourceField = readSourceFieldFragment(
    client,
    filterConfig.actualFilter?.masterBlueprintSourceFieldID
  );

  return (
    <EditableArrayItemCard
      {...{
        className,
        title: 'Фильтр по значениям',
        onDelete,
      }}
    >
      <RadioGroup
        {...{
          name: `${fieldPrefix}mode`,
          variant: RadioGroupVariants.segmented,
          items: groupingModeItems,
          value: selectedFilterMode,
          onValueChange: newValue => {
            emptySwitchableFormFields(
              formContext,
              newValue === FiltersAndGroupingsModes.actual,
              {
                [`${fieldPrefix}actualFilter`]: null,
              },
              {
                [`${fieldPrefix}historicFilter`]: null,
              }
            );
          },
        }}
      />
      {isActualFilterMode && (
        <>
          <BlueprintSourceFieldAsyncSelect
            {...{
              name: `${fieldPrefix}actualFilter.masterBlueprintSourceFieldID`,
              label: 'Параметр фильтрации',
              theme: SelectThemes.light,
              onValueChange: () => {
                formContext.setValue(`${fieldPrefix}actualFilter.value`, null);
              },
              queryOptions: {
                variables: {
                  onlyMaster: true,
                },
              },
            }}
          />
          {selectedSourceField &&
            renderInputByValueKind(
              selectedSourceField.returnValueKind,
              {
                name: `${fieldPrefix}actualFilter.value`,
                label: 'Значение',
              },
              {
                selectProps: {
                  theme: SelectThemes.light,
                },
                inputProps: {
                  theme: InputThemes.light,
                },
                dateInputProps: {
                  theme: DateInputThemes.light,
                },
              }
            )}
        </>
      )}
      {!isActualFilterMode && (
        <>
          <CustomMilkingReportSettingsHistoricKindSelect
            {...{
              name: `${fieldPrefix}historicFilter.kind`,
              label: 'Параметр фильтрации',
              theme: SelectThemes.light,
              onValueChange: newValue => {
                if (!newValue?.id) return;

                const valueToSet = match(
                  newValue.id as CustomMilkingReportSettingsHistoricKind
                )
                  .with(
                    CustomMilkingReportSettingsHistoricKind.Lactation,
                    () => ({
                      lactationNumbers: [],
                      lactationGroupNumbers: null,
                      penGroupIDs: null,
                    })
                  )
                  .with(
                    CustomMilkingReportSettingsHistoricKind.LactationGroup,
                    () => ({
                      lactationNumbers: null,
                      lactationGroupNumbers: [],
                      penGroupIDs: null,
                    })
                  )
                  .with(
                    CustomMilkingReportSettingsHistoricKind.PenGroup,
                    () => ({
                      lactationNumbers: null,
                      lactationGroupNumbers: null,
                      penGroupIDs: [],
                    })
                  )
                  .exhaustive();

                formContext.setValue(
                  `${fieldPrefix}historicFilter.value`,
                  valueToSet
                );
              },
            }}
          />
          {!!filterConfig.historicFilter?.kind &&
            match(filterConfig.historicFilter.kind)
              .with(CustomMilkingReportSettingsHistoricKind.Lactation, () => (
                <Select
                  {...{
                    name: `${fieldPrefix}historicFilter.value.lactationNumbers`,
                    ...commonHistoricFilterValueSelectProps,
                    items: R.range(
                      1,
                      companyDetailed?.maxLactationNumber ?? 1
                    ).map(lactationNumber => ({
                      id: lactationNumber,
                      name: `${lactationNumber}-я`,
                    })),
                    asyncProps: {
                      isLoading: isDetailedCompanyLoading,
                    },
                  }}
                />
              ))
              .with(CustomMilkingReportSettingsHistoricKind.PenGroup, () => {
                const fieldName =
                  `${fieldPrefix}historicFilter.value.penGroupIDs` as const;
                return (
                  <PenGroupAsyncSelect
                    {...{
                      name: fieldName,
                      rawValue: formContext.watch(fieldName) ?? [],
                      ...commonHistoricFilterValueSelectProps,
                    }}
                  />
                );
              })
              .with(
                CustomMilkingReportSettingsHistoricKind.LactationGroup,
                () => (
                  <LactationGroupNumberSelect
                    {...{
                      name: `${fieldPrefix}historicFilter.value.lactationGroupNumbers`,
                      ...commonHistoricFilterValueSelectProps,
                    }}
                  />
                )
              )
              .exhaustive()}
        </>
      )}
    </EditableArrayItemCard>
  );
};

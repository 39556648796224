import React, { useMemo } from 'react';

import { ChartType } from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import {
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { MDASH } from '~/shared/constants';
import { DateFormats, formatDate } from '~/shared/helpers/date';
import { formatInt } from '~/shared/helpers/number';
import { capitalize } from '~/shared/helpers/string';

import {
  BarChart,
  BarChartDataPoint,
  CHART_DATASET_COLORS_ITERATOR,
  CHART_DATASET_HOVER_COLORS_ITERATOR,
  CHART_DATASET_PRESSED_COLORS_ITERATOR,
  getScaleOptions,
  ReactChartDatasetConfig,
} from '~/features/charts';

import panelStyles from '~/styles/modules/panel.module.scss';

import { LivestockForecastMonthFragment } from '../../gql/fragments/livestockForecastMonth.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Data for the chart
   */
  livestockForecastMonths: (
    | LivestockForecastMonthFragment
    | SkeletonPlaceholder
  )[];
}

const CHART_HEIGHT_PX = 180;

type CalvingsAndHeadsChartConfig = ReactChartDatasetConfig<
  ChartType,
  BarChartDataPoint[]
> & {
  tooltipLabel: string;
};

export const CalvingsAndHeadsChart: React.FC<Props> = ({
  className,
  livestockForecastMonths,
}) => {
  const { getSkeletonClassNames } = useSkeletonContext();

  const { datasets, maxNewbornAnimals, labels } = useMemo(() => {
    const newbornHeifersCounts = livestockForecastMonths.map(
      m => m.calvings?.newbornHeifers ?? 0
    );
    const newbornBullsCounts = livestockForecastMonths.map(
      m => m.calvings?.newbornBulls ?? 0
    );

    const configs = [
      {
        key: 'heifersCalvingsChart',
        label: 'Отёлы нетелей',
        color: CHART_DATASET_COLORS_ITERATOR.next(true).value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next(true).value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next(true).value,
        order: 3,
        data: livestockForecastMonths.map(m => m.calvings?.heifers ?? 0),
        tooltipLabel: 'Нетели',
      },
      {
        key: 'cowsCalvingsChart',
        label: 'Отёлы коров',
        color: CHART_DATASET_COLORS_ITERATOR.next().value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next().value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next().value,
        order: 4,
        data: livestockForecastMonths.map(
          m =>
            (m.calvings?.firstLactation ?? 0) +
            (m.calvings?.otherLactations ?? 0)
        ),
        tooltipLabel: 'Коровы',
      },
      {
        key: 'expectedHeifersChart',
        label: 'Ожидается тёлок',
        color: CHART_DATASET_COLORS_ITERATOR.next().value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next().value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next().value,
        type: 'line',
        yAxisID: 'heads',
        order: 1,
        isTogglable: true,
        data: newbornHeifersCounts,
        tooltipLabel: 'Тёлок',
      },
      {
        key: 'expectedBullsChart',
        label: 'Ожидается быков',
        color: CHART_DATASET_COLORS_ITERATOR.next().value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next().value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next().value,
        type: 'line',
        yAxisID: 'heads',
        order: 2,
        isTogglable: true,
        data: newbornBullsCounts,
        tooltipLabel: 'Бычков',
      },
    ] satisfies CalvingsAndHeadsChartConfig[];

    return {
      datasets: configs,
      maxNewbornAnimals:
        Math.ceil(
          Math.max(...newbornHeifersCounts, ...newbornBullsCounts) / 10
        ) * 10,
      labels: livestockForecastMonths.map(month =>
        formatDate(month?.forecastAt, DateFormats.onlyMonthShort)
      ),
    };
  }, [livestockForecastMonths]);

  return (
    <div className={clsx('p-24', panelStyles.largePanel, className)}>
      <Typography
        {...{
          variant: TypographyVariants.bodyLargeStrong,
          isStaticContent: true,
        }}
      >
        Отёлы и головы
      </Typography>

      <BarChart
        {...{
          height: CHART_HEIGHT_PX,
          legendClassName: clsx(
            'mb-24',
            getSkeletonClassNames('mt-16', 'mt-24')
          ),
          isStackedX: true,
          datasets,
          labels,
          renderTooltip: dataPoints => {
            const sortedPoints = R.sortBy(R.prop('datasetIndex'), dataPoints);

            const monthIndex = sortedPoints[0].parsed.x;
            return (
              <div>
                <Typography
                  {...{
                    variant: TypographyVariants.descriptionLarge,
                    tag: 'h4',
                    className: 'mb-8',
                  }}
                >
                  {capitalize(
                    formatDate(
                      livestockForecastMonths[monthIndex]?.forecastAt,
                      DateFormats.monthAndYear
                    )
                  )}
                </Typography>
                <ul className="grid gap-4">
                  {sortedPoints.map(point => {
                    const currentChartConfig = datasets.at(point.datasetIndex);
                    if (!currentChartConfig) return null;

                    return (
                      <Typography
                        {...{
                          tag: 'li',
                          variant: TypographyVariants.descriptionLarge,
                          key: point.datasetIndex,
                          className: 'flex gap-8 items-center',
                        }}
                      >
                        <ColoredDot
                          size={ColoredDotSizes.small10}
                          color={currentChartConfig.color}
                        />
                        {`${formatInt(point.parsed.y)} ${MDASH} ${
                          currentChartConfig.tooltipLabel
                        }`}
                      </Typography>
                    );
                  })}
                </ul>
              </div>
            );
          },
          chartOptions: {
            interaction: {
              mode: 'index',
            },
            scales: {
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: 'Отёлы',
                },
              },
              heads: getScaleOptions({
                min: 0,
                max: maxNewbornAnimals,
                position: 'right',
                title: {
                  display: true,
                  text: 'Головы',
                },
                ticks: {
                  callback: value => formatInt(value),
                },
              }),
            },
          },
        }}
      />
    </div>
  );
};

import React, { FormEventHandler } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { usePress } from '@react-aria/interactions';
import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import styles from '../../index.module.scss';
import { TabConfig, TabsVariants } from '../../types';

interface Props<Config extends TabConfig = TabConfig> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Visual display variant of the tabs
   */
  variant?: TabsVariants;
  /**
   * Tab config for rendering
   */
  tabConfig: Config;
}

export const Tab: React.FC<Props> = ({ className, variant, tabConfig }) => {
  const { pressProps, isPressed } = usePress({});

  // :focus-visible is not working with usePress correctly, so we use react-aria solution
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Link
      {...{
        to: tabConfig.href,
        className: clsx(className, styles.tab, {
          [styles.pressed]: isPressed,
          [styles.focused]: isFocusVisible,
        }),
        ...(mergeProps(pressProps, focusProps) as FormEventHandler<'a'>),
        activeProps: {
          className: styles.active,
        },
        resetScroll: false,
        params: true,
      }}
    >
      {({ isActive }) => {
        // TODO remove, when we rework old tabs
        let typographyVariant =
          isActive && variant === TabsVariants.primary
            ? TypographyVariants.heading5
            : TypographyVariants.bodySmall;

        if (variant === TabsVariants.transparent) {
          typographyVariant = TypographyVariants.bodyMediumStrong;
        }

        return (
          <Typography variant={typographyVariant}>{tabConfig.title}</Typography>
        );
      }}
    </Link>
  );
};

import React, { useEffect } from 'react';

import { createFileRoute, useRouterState } from '@tanstack/react-router';
import R from 'ramda';

import { AsyncList } from '~/shared/components/AsyncList';
import { Button } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { SelectThemes } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import { useBlueprintRoleSelect } from '~/entities/blueprintRoles';
import {
  useCustomReportsPaginatedQuery,
  useEditCustomReportModal,
} from '~/entities/customReports';
import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';

import { useCustomReportSettingsModal } from '~/features/customReportLaunch';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';

import { CustomReportCard } from './components';

const CUSTOM_REPORT_CARDS_DEFAULT_COUNT = 4;

interface CustomReportsSearchParams {
  blueprintRoleIds: string[];
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/custom-reports/'
)({
  wrapInSuspense: true,
  component: CustomReportsPage,
  validateSearch: ({
    blueprintRoleIds,
  }: WithSearchParamsValidation<CustomReportsSearchParams>) => ({
    blueprintRoleIds: Array.isArray(blueprintRoleIds) ? blueprintRoleIds : [],
  }),
});

function CustomReportsPage() {
  const { blueprintRoleIds, setBlueprintRoleIds } =
    useSearchParamsState<typeof Route>();
  const routerState = useRouterState()?.redirect?.state;

  const customReportIdForModal =
    typeof routerState === 'object'
      ? (routerState?.data?.customReportId as string)
      : undefined;

  const { open: openCustomReportSettingsModal } =
    useCustomReportSettingsModal();

  const queryVariables = {
    roleIDs: blueprintRoleIds,
  };

  // Open a modal with custom report, if we have a param passed from redirection in the url
  useEffect(() => {
    if (customReportIdForModal) {
      openCustomReportSettingsModal({ customReportId: customReportIdForModal });
    }
  }, [customReportIdForModal]);

  const { open: openEditCustomReportModal } = useEditCustomReportModal();

  const createCustomReportElement = (
    <Button
      {...{
        iconVariant: IconVariants.plus,
        onPress: () =>
          openEditCustomReportModal({
            onSave: newCustomReport => {
              if (newCustomReport) {
                openCustomReportSettingsModal({
                  customReportId: newCustomReport.id,
                });
              }
            },
            queryVariables,
          }),
      }}
    >
      Создать отчёт
    </Button>
  );

  const { items: customReportItems, ...asyncProps } =
    useCustomReportsPaginatedQuery({
      variables: queryVariables,
    });

  const { renderSelectElement: renderBlueprintRoleSelectElement } =
    useBlueprintRoleSelect({
      selectProps: {
        isMulti: true,
        className: 'mb-24 default-control',
        name: 'blueprintRoles',
        rawValue: blueprintRoleIds,
        theme: SelectThemes.light,
        onValueChange: newRoleIds =>
          setBlueprintRoleIds(newRoleIds.map(R.prop('id'))),
        isUsedAsFilter: true,
      },
    });

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Отчёты',
          rightContent:
            (!!customReportItems.length || asyncProps.isLoading) &&
            createCustomReportElement,
        }}
      />

      {renderBlueprintRoleSelectElement()}

      <AsyncList<CustomReportFragment>
        {...{
          className: contentGridStyles.autoGridDynamicItems,
          wrapperTag: 'div',
          items: customReportItems,
          skeletonItemsCount: CUSTOM_REPORT_CARDS_DEFAULT_COUNT,
          noItemsMessage: (
            <div className="col-span-full py-96 grid place-items-center gap-16">
              <Typography variant={TypographyVariants.bodySmallStrong} tag="p">
                Отчёты пока не созданы
              </Typography>
              {createCustomReportElement}
            </div>
          ),
          renderItem: customReport => (
            <CustomReportCard
              {...{
                key: customReport.id,
                customReport,
                queryVariables,
              }}
            />
          ),
          ...asyncProps,
        }}
      />
    </div>
  );
}

import React from 'react';

import { Accordion } from '~/shared/components/Accordion';
import { UseAccordionProps } from '~/shared/hooks/useAccordion';

import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';
import { CowFieldFragment } from '~/entities/cows/gql/fragments/cowField.graphql';

import { getCowFieldId } from '../../helpers';
import { useCowPinnedFields } from '../../hooks';
import { CowField } from '../CowField';
import styles from './index.module.scss';

/**
 * Section should be passed in a special prepared for rendering config,
 * cause here we abstract from static and calculated sections logic
 * and only visualize them in the same manner
 */
export interface CowFieldsSectionRenderConfig {
  name: string;
  staticFields: CowFieldFragment[];
  calculatedFields: CowFieldFragment[];
}

interface Props extends UseAccordionProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Prepared for rendering section config
   */
  sectionConfig: CowFieldsSectionRenderConfig;
  /**
   * Current rendering cow
   */
  cow: CowDetailedFragment;
}

export const CowFieldsSection: React.FC<Props> = ({
  className,
  sectionConfig,
  cow,

  ...accordionProps
}) => {
  const { setCowFieldIsPinned } = useCowPinnedFields(cow);

  const fieldsToRender = [
    ...sectionConfig.staticFields,
    ...sectionConfig.calculatedFields,
  ];

  return (
    <Accordion
      {...{
        className,
        title: sectionConfig.name,
        ...accordionProps,
      }}
    >
      <div className={styles.fields}>
        {fieldsToRender.map(field => (
          <CowField
            {...{
              key: getCowFieldId(field),
              field,
              onFieldPin: isPinned => setCowFieldIsPinned(field, isPinned),
            }}
          />
        ))}
      </div>
    </Accordion>
  );
};

import React, { useMemo } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { AppLocalStorageKeys } from '~/shared/constants';
import { useLocalStorage } from '~/shared/hooks/useLocalStorage';

import {
  COW_SECTIONS_CONFIGS,
  CowStaticSectionConfigs,
  useEditCowModal,
} from '~/entities/cows';
import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';
import { useCowDetailedQuery } from '~/entities/cows/gql/queries/cowDetailed.graphql';

import {
  CowFieldsSection,
  CowFieldsSectionRenderConfig,
  CowPinnedFields,
  isCowStaticField,
} from '~/features/cowFields';

import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

type CowSectionRenderConfig = Record<string, CowFieldsSectionRenderConfig>;

interface CowFieldsRenderConfig {
  staticSections: CowSectionRenderConfig;
  calculatedSections: CowSectionRenderConfig;
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/cows/$cowId/animal-fields/'
)({
  wrapInSuspense: true,
  component: AnimalFieldsTabContent,
});

function AnimalFieldsTabContent() {
  const { open: openEditCowModal } = useEditCowModal();

  const { cowId } = Route.useParams();

  const { data: cowDetailedData } = useCowDetailedQuery({
    variables: {
      id: cowId ?? '',
    },
    skip: !cowId,
  });

  const cow = cowDetailedData?.cow as CowDetailedFragment;

  const [sectionIsOpenDict, setSectionIsOpenDict] = useLocalStorage<
    Record<string, boolean>
  >(AppLocalStorageKeys.cowCardSectionIsOpen, {});

  const { staticSections, calculatedSections } =
    useMemo<CowFieldsRenderConfig>(() => {
      const staticSectionsInitial = R.map<
        CowStaticSectionConfigs,
        CowFieldsSectionRenderConfig
      >(
        sectionConfig => ({
          name: sectionConfig.name,
          staticFields: [],
          calculatedFields: [],
        }),
        COW_SECTIONS_CONFIGS
      );

      return cow.fields.reduce(
        (acc, field) => {
          const fieldSectionKind = field.section.kind;

          // Static fields should be sorted into hardcoded sections
          if (isCowStaticField(field)) {
            const staticSectionForStaticField = Object.entries(
              COW_SECTIONS_CONFIGS
            ).find(([, sectionConfig]) =>
              sectionConfig.fieldKinds.includes(field.kind)
            );

            if (staticSectionForStaticField) {
              acc.staticSections[
                staticSectionForStaticField[0]
              ].staticFields.push(field);
            }

            return acc;
          }

          // Some calculated fields should be added into static sections according to configs
          const staticSectionForCalculatedField = Object.entries(
            COW_SECTIONS_CONFIGS
          ).find(([, sectionConfig]) =>
            sectionConfig.calculatedSectionKinds.includes(fieldSectionKind)
          );

          if (staticSectionForCalculatedField) {
            acc.staticSections[
              staticSectionForCalculatedField[0]
            ].calculatedFields.push(field);

            return acc;
          }

          // If we didn't find a static section for a field, just add it to calculated sections
          acc.calculatedSections[fieldSectionKind] = acc.calculatedSections[
            fieldSectionKind
          ] ?? {
            name: field.section.verboseName,
            staticFields: [],
            calculatedFields: [],
          };

          acc.calculatedSections[fieldSectionKind].calculatedFields.push(field);

          return acc;
        },
        {
          staticSections: staticSectionsInitial,
          calculatedSections: {},
          unsorted: [],
        } as CowFieldsRenderConfig
      );
    }, [cow]);

  const renderSections = (sectionConfigs: CowSectionRenderConfig) => {
    return Object.entries(sectionConfigs).map(([sectionKey, sectionConfig]) => (
      <CowFieldsSection
        {...{
          key: sectionKey,
          sectionConfig,
          isOpen: sectionIsOpenDict[sectionKey] ?? true,
          onToggle: isOpen =>
            setSectionIsOpenDict(current => ({
              ...current,
              [sectionKey]: isOpen,
            })),
          cow,
        }}
      />
    ));
  };

  const outerPanelClassName = clsx(
    panelStyles.largeDarkPanel,
    'flex flex-col gap-12 p-16'
  );

  return (
    <div className="grid items-start grid-cols-3 gap-24">
      <div className="flex flex-col gap-24 col-span-2">
        <div className={outerPanelClassName}>
          <div className="flex items-center justify-between">
            <Typography tag="h2" variant={TypographyVariants.heading4}>
              Информация
            </Typography>

            <Button
              {...{
                variant: ButtonVariants.ghost,
                iconVariant: IconVariants.edit,
                size: ButtonSizes.small24,
                onPress: () => openEditCowModal({ cow }),
              }}
            >
              Редактировать
            </Button>
          </div>

          {renderSections(staticSections)}
        </div>

        <div className={outerPanelClassName}>
          <Typography tag="h2" variant={TypographyVariants.heading4}>
            Показатели
          </Typography>

          {renderSections(calculatedSections)}
        </div>
      </div>
      <div className={styles.pinnedFieldsContainer}>
        <CowPinnedFields
          {...{
            className: styles.pinnedFields,
            cow,
          }}
        />
      </div>
    </div>
  );
}

import { CustomMilkingReportSettingsHistoricKind } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_HISTORIC_KINDS_DICT } from '../../../../constants';

const useCustomMilkingReportSettingsHistoricKindSelect = makeUseEnumSelect(
  CustomMilkingReportSettingsHistoricKind,
  enumValue =>
    CUSTOM_MILKING_REPORT_HISTORIC_KINDS_DICT[
      enumValue as CustomMilkingReportSettingsHistoricKind
    ]
);

export const CustomMilkingReportSettingsHistoricKindSelect =
  makeSelectComponentFromHook(useCustomMilkingReportSettingsHistoricKindSelect);

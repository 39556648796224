import React, { useRef, useState } from 'react';

import {
  CalendarDate,
  createCalendar,
  parseDate,
} from '@internationalized/date';
import { AriaDateFieldProps, useDateField } from '@react-aria/datepicker';
import { useDateFieldState } from '@react-stately/datepicker';
import clsx from 'clsx';
import R from 'ramda';

import { DateTimeInputSegment } from '~/shared/components/DateTimeInputSegment';
import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Label } from '~/shared/components/Label';
import { DEFAULT_LOCALE } from '~/shared/constants';
import { formatDateForBackend } from '~/shared/helpers/date';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import styles from './index.module.scss';

/**
 * Possible date input display variants
 */
export enum DateInputThemes {
  light = 'light',
  dark = 'dark',
}

export interface DateInputProps extends BaseFieldProps<string | null> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Date input display theme
   */
  theme?: DateInputThemes;
  /**
   * If isOnlyDate is true, than returns only date, ISO otherwise (default = true)
   */
  isOnlyDate?: boolean;
}

const DateInputInner = React.forwardRef<HTMLSpanElement, DateInputProps>(
  (
    {
      name,
      className,
      theme = DateInputThemes.dark,

      isOnlyDate = true,

      isDisabled,
      isRequired,

      value: valueProp,
      defaultValue: defaultValueProp,
      onValueChange,

      label,
      labelProps,
      hasError,
      feedback,
      feedbackProps,

      ...other
    }: DateInputProps,
    ref
  ) => {
    const submitButtonRef = useRef<React.ElementRef<'button'>>(null);
    const inputRef = useRef<React.ElementRef<'input'>>(null);

    const parseDateProp = (val: string | null | undefined) => {
      if (R.isNil(val)) return val;
      if (!val) return null;

      return parseDate(formatDateForBackend(val, true));
    };

    const parsedValue = parseDateProp(valueProp);
    const parsedDefaultValue = parseDateProp(defaultValueProp);

    // Additional state is needed to store some uncompleted dates
    const [innerValue, setInnerValue] = useState(
      parsedValue ?? parsedDefaultValue
    );

    const [value, setValue] = useControllableState<CalendarDate | null>(
      parsedValue,
      newValue => {
        setInnerValue(newValue);
        onValueChange?.(
          formatDateForBackend(newValue?.toString(), isOnlyDate) ?? null
        );
      },
      parsedDefaultValue
    );

    const dateFieldVendorProps: AriaDateFieldProps<CalendarDate> = {
      ...other,
      name,
      label,
      value: (innerValue?.year !== value?.year ? innerValue : value) ?? value,
      onChange: setValue,
      onKeyDown: e => {
        if (e.key === 'Enter') {
          submitButtonRef.current?.click();
        }
      },
    };

    const state = useDateFieldState({
      locale: DEFAULT_LOCALE,
      createCalendar,
      ...dateFieldVendorProps,
    });

    const innerRef = useRef<React.ElementRef<'div'>>(null);
    const { labelProps: labelPropsVendor, fieldProps: fieldPropsVendor } =
      useDateField(dateFieldVendorProps, state, innerRef);

    return (
      <div
        className={clsx(styles.root, className, styles[theme], {
          [styles.disabled]: isDisabled,
          [styles.error]: hasError,
        })}
      >
        {!!label && (
          <Label
            {...{
              ...labelProps,
              ...labelPropsVendor,
              isRequired,
            }}
          >
            {label}
          </Label>
        )}
        <div className={styles.inputContainer}>
          <div
            {...{
              ...fieldPropsVendor,
              className: 'flex items-center flex-1',
              ref: innerRef,
            }}
          >
            {state.segments.map((segment, index) => (
              <DateTimeInputSegment
                {...{
                  key: index,
                  segment,
                  state,
                }}
              />
            ))}
          </div>
          <Icon
            {...{
              ref,
              variant: IconVariants.calendar,
              onPress: () => {
                inputRef.current?.showPicker();
              },
              className: 'text-muted',
            }}
          />
          <button hidden type="submit" ref={submitButtonRef} />
          <input
            {...{
              name: `${name}__picker`,
              ref: inputRef,
              type: 'date',
              tabIndex: -1,
              disabled: isDisabled,
              className: styles.input,
              onChange: e => {
                const inputValue = e.target.value;
                const valueToSet = inputValue ? parseDate(inputValue) : null;
                setValue(valueToSet);
              },
              value: formatDateForBackend(innerValue?.toString(), true),
            }}
          />
        </div>
        {!!feedback && <FieldFeedback content={feedback} {...feedbackProps} />}
      </div>
    );
  }
);

export const DateInput = withOptionalFormController<
  DateInputProps,
  string | null
>({
  defaultValue: null,
})(DateInputInner);

import React from 'react';

import { CalculationMethodEnum } from '@graphql-types';
import R from 'ramda';
import * as yup from 'yup';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { Input, InputVariants } from '~/shared/components/Input';
import { makeUseEnumSelect, Select } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { oneOfEnum } from '~/shared/helpers/yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { BlueprintAsyncSelect } from '~/entities/blueprints';
import { useBlueprintDetailedQuery } from '~/entities/blueprints/gql/queries/blueprintDetailed.graphql';

import formStyles from '~/styles/modules/form.module.scss';

import { MonitorGroupAsyncSelect } from '../../components';
import { MonitorEntryFragment } from '../../gql/fragments/monitorEntry.graphql';
import { useCreateMonitorEntryMutation } from '../../gql/mutations/createMonitorEntry.graphql';
import { useUpdateMonitorEntryMutation } from '../../gql/mutations/updateMonitorEntry.graphql';
import { useCreateMonitorGroupModal } from '..';

const useCalculationMethodSelect = makeUseEnumSelect(
  CalculationMethodEnum,
  EnumStrings.calculationMethod
);

export interface EditMonitorEntryModalProps
  extends InjectedModalProps<EditMonitorEntryModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing monitor entry, if not passed, a new one is created
   */
  monitorEntry?: MonitorEntryFragment;
}

const FORM_ID = 'EditMonitorEntryForm';

const SCHEMA = yup.object({
  name: yup.string().required(),
  target: yup.number().nullable().default(null),
  monitorGroupID: yup.string().required(), // ID!

  calculationMethod: oneOfEnum<CalculationMethodEnum>(
    CalculationMethodEnum
  ).default(CalculationMethodEnum.Total),
  leftBlueprintID: yup.string().required(), // ID!
  leftBlueprintSourceFieldID: yup
    .string()
    .nullable()
    .when('calculationMethod', {
      is: CalculationMethodEnum.Average,
      then: s => s.required(),
    }), // ID
  rightBlueprintID: yup
    .string()
    .nullable()
    .when('calculationMethod', {
      is: CalculationMethodEnum.Percent,
      then: s => s.required(),
    }), // ID
});

type EditMonitorEntryFormType = InferValidatedSchema<typeof SCHEMA>;

const BLUEPRINT_SELECT_QUERY_OPTIONS = {
  variables: { canBeUsedInMonitor: true },
} as const;

export const EditMonitorEntryModal: React.FC<EditMonitorEntryModalProps> = ({
  className,
  monitorEntry,
  close,
}) => {
  const isEditing = !!monitorEntry;

  const formContext = useForm<EditMonitorEntryFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['name', 'target', 'calculationMethod'],
        monitorEntry ?? ({} as MonitorEntryFragment)
      ),
      leftBlueprintID: monitorEntry?.leftBlueprint.id,
      leftBlueprintSourceFieldID: monitorEntry?.leftBlueprintSourceField?.id,
      rightBlueprintID: monitorEntry?.rightBlueprint?.id,
      monitorGroupID: monitorEntry?.monitorGroup.id,
    },
  });

  const [createMonitorEntry, { loading: isCreateMonitorEntryLoading }] =
    useCreateMonitorEntryMutation();

  const [updateMonitorEntry, { loading: isUpdateMonitorEntryLoading }] =
    useUpdateMonitorEntryMutation();

  const handleSubmit = async (form: EditMonitorEntryFormType) => {
    if (isEditing) {
      await updateMonitorEntry({
        variables: {
          id: monitorEntry.id,
          input: form,
        },
        refetchQueries: ['monitor'],
      });
    } else {
      await createMonitorEntry({
        variables: {
          input: form,
        },
        refetchQueries: ['monitor'],
      });
    }
    close();
  };

  const { calculationMethod, leftBlueprintID, rightBlueprintID } =
    formContext.watch();

  const { data: blueprintDetailedData, loading: isBlueprintDetailedLoading } =
    useBlueprintDetailedQuery({
      variables: {
        id: leftBlueprintID ?? '',
      },
      skip: !leftBlueprintID,
    });
  const blueprintDetailed = blueprintDetailedData?.blueprint;

  const { open: openCreateMonitorGroupModal } = useCreateMonitorGroupModal();

  const { renderSelectElement: renderCalculationMethodSelectElement } =
    useCalculationMethodSelect({
      name: 'calculationMethod',
      label: 'Метод расчёта',
    });

  return (
    <Modal
      {...{
        className,
        title: isEditing ? 'Редактирование показателя' : 'Новый показатель',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isCreateMonitorEntryLoading || isUpdateMonitorEntryLoading,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        className={formStyles.singleColumnForm}
        formContext={formContext}
        id={FORM_ID}
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <Typography variant={TypographyVariants.bodyMediumStrong}>
          Общее
        </Typography>
        <div className={formStyles.twoColumnForm}>
          <Input
            {...{
              name: 'name',
              label: 'Название показателя',
              placeholder: 'Введите значение',
            }}
          />
          <Input
            {...{
              variant: InputVariants.int,
              name: 'target',
              label: 'Целевое значение (Необязательно)',
              placeholder: 'Введите значение',
            }}
          />
        </div>
        <MonitorGroupAsyncSelect
          {...{
            name: 'monitorGroupID',
            label: 'Группа показателя',
            listActionLabel: 'Добавить новую группу',
            onListActionPress: () =>
              openCreateMonitorGroupModal({
                onSubmit: newGroup =>
                  formContext.setValue('monitorGroupID', newGroup.id),
              }),
          }}
        />
        <Typography
          className="block mt-4"
          variant={TypographyVariants.bodyMediumStrong}
        >
          Расчёт
        </Typography>

        {renderCalculationMethodSelectElement({
          onValueChange: () => {
            formContext.setValue('leftBlueprintSourceFieldID', null);
            formContext.setValue('rightBlueprintID', null);
          },
        })}
        <BlueprintAsyncSelect
          {...{
            queryOptions: BLUEPRINT_SELECT_QUERY_OPTIONS,
            name: 'leftBlueprintID',
            label: 'Команда для расчёта',
            className: 'col-start-1',
            rawValue: leftBlueprintID,
            onValueChange: blueprint => {
              if (!blueprint) return;
              formContext.setValue('leftBlueprintSourceFieldID', null);
              // For some reason react-hook-form doesn't update the watcher for leftBlueprintID
              // after a validation error, so we need to trigger additional render as a workaround
              setTimeout(() => {
                formContext.setValue('name', formContext.getValues('name'));
              }, 0);
            },
          }}
        />
        {calculationMethod === CalculationMethodEnum.Percent && (
          <BlueprintAsyncSelect
            {...{
              queryOptions: BLUEPRINT_SELECT_QUERY_OPTIONS,
              name: 'rightBlueprintID',
              label: 'Команда для расчёта',
              rawValue: rightBlueprintID,
            }}
          />
        )}
        {calculationMethod === CalculationMethodEnum.Average &&
          !!leftBlueprintID && (
            <Select
              {...{
                key: leftBlueprintID,
                name: 'leftBlueprintSourceFieldID',
                label: 'Поле для расчёта',
                items:
                  blueprintDetailed?.sourceSections.flatMap(R.prop('fields')) ??
                  [],
                asyncProps: {
                  isLoading: isBlueprintDetailedLoading,
                },
              }}
            />
          )}
      </Form>
    </Modal>
  );
};

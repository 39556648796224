import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { Skeleton } from '~/shared/components/Skeleton';

import TOKENS from '~/styles/__generated__/tokens.json';

import styles from './index.module.scss';

const COLUMNS_COUNT = 16;

const BLOCKS_PER_COLUMN_COUNT = 15;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const SkeletonMilkingParlorChart: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(styles.root, className)}>
      {R.range(0, COLUMNS_COUNT).map(columnIndex => (
        <div
          {...{
            className: styles.column,
            key: columnIndex,
          }}
        >
          {R.range(0, BLOCKS_PER_COLUMN_COUNT).map(blockIndex => (
            <Skeleton.Block
              {...{
                key: blockIndex,
                width: TOKENS.size36,
                height: TOKENS.size4,
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

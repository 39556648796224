import React, { useEffect, useRef, useState } from 'react';

import R from 'ramda';

import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';

import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';
import {
  EditableReportCard,
  EditableReportCardStates,
} from '~/features/customReportsBuilder';

import { CUSTOM_REPORT_CHART_SETTINGS_FORM_ID } from '../../constants';
import { useCalculateCustomReportChartMutation } from '../../gql/mutations/calculateCustomReportChart.graphql';
import { mapCustomReportChartSettingsToForm } from '../../helpers';
import { useCustomReportChartSettingsCRUD } from '../../hooks';
import { CustomReportChart } from '../CustomReportChart';
import { CustomReportChartSettingsForm } from '../CustomReportChartSettingsForm';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If passed, renders an existing chart from customReport settings, new chart otherwise
   */
  chartIndex?: number;
  /**
   * Custom report to take settings from
   */
  customReport: CustomReportDetailedFragment;
  /**
   * Launch result to render chart
   */
  customReportLaunchResult: CustomReportLaunchResultFragment;
  /**
   * Called, when we cancel of finish creation
   */
  onCreationFinish?: (
    chartData: CustomReportLaunchResultFragment['charts'][number] | undefined
  ) => void;
}

export const CustomReportChartCard: React.FC<Props> = ({
  className,
  chartIndex,
  customReport,
  customReportLaunchResult,
  onCreationFinish,
}) => {
  const isNew = chartIndex === undefined;

  const initialChartSettings = isNew
    ? undefined
    : customReport.chartsSettings[chartIndex];

  const initialChartSettingsForm =
    mapCustomReportChartSettingsToForm(initialChartSettings);

  const initialChartData = isNew
    ? undefined
    : customReportLaunchResult.charts[chartIndex];

  // State for default data, cause we might have multiple updates, but we don't reassign customReportLaunchResult
  const [defaultChartData, setDefaultChartData] = useState(initialChartData);

  const [cardState, setCardState] = useState(
    isNew ? EditableReportCardStates.create : EditableReportCardStates.view
  );

  const {
    cardRef,
    createCustomReportChartSettings,
    isCreateCustomReportChartSettingsLoading,

    updateCustomReportChartSettings,
    isUpdateCustomReportChartSettingsLoading,

    deleteCustomReportChartSettings,
  } = useCustomReportChartSettingsCRUD({
    customReport,
    chartSettings: initialChartSettings,
  });

  const abortControllerRef = useRef<AbortController>();

  const [
    calculateCustomReportChart,
    { reset: resetCalculateCustomReportChartMutation },
  ] = useCalculateCustomReportChartMutation();
  const [requestedChartSettings, setRequestedChartSettings] = useState(
    R.omit(['name'], initialChartSettingsForm)
  );

  const [currentChartData, setCurrentChartData] = useState(initialChartData);

  // If the custom report launch changes, we should reset chartData, cause we can receive errors there
  useEffect(() => {
    setCurrentChartData(initialChartData);
  }, [customReportLaunchResult]);

  return (
    <EditableReportCard
      {...{
        ref: cardRef,
        title: initialChartSettings?.name,
        updatingTitle: 'Настройки диаграммы',
        creatingTitle: 'Создание диаграммы',
        className,
        cardState,
        onCardStateChange: newCardState => {
          // Cancel is pressed
          if (newCardState === EditableReportCardStates.view) {
            abortControllerRef.current?.abort();
            resetCalculateCustomReportChartMutation();
            setRequestedChartSettings(initialChartSettingsForm);
            setCurrentChartData(defaultChartData);

            if (isNew) {
              onCreationFinish?.(undefined);
            }
          }
          setCardState(newCardState);
        },
        submitButtonProps: {
          form: CUSTOM_REPORT_CHART_SETTINGS_FORM_ID,
          isLoading:
            isCreateCustomReportChartSettingsLoading ||
            isUpdateCustomReportChartSettingsLoading,
        },
        onDelete: deleteCustomReportChartSettings,
        renderEditingContent: () => (
          <CustomReportChartSettingsForm
            {...{
              customReport,
              customReportLaunchResult,
              chartSettings: initialChartSettings,
              onFormSettingsChange: settingsInput => {
                abortControllerRef.current?.abort();

                abortControllerRef.current = new AbortController();

                calculateCustomReportChart({
                  variables: {
                    customReportHashID: customReportLaunchResult.hashID,
                    settingsInput,
                  },
                  context: {
                    fetchOptions: {
                      signal: abortControllerRef.current.signal,
                    },
                    shouldSkipErrorNotifications: true,
                  },
                })
                  .then(({ data }) => {
                    if (data?.calculateCustomReportChart.__typename) {
                      setRequestedChartSettings(settingsInput);
                      setCurrentChartData(data.calculateCustomReportChart);
                    }
                  })
                  .catch(() => {});
              },
              onSubmit: form => {
                if (isNew) {
                  createCustomReportChartSettings(form).then(() => {
                    // This will delete the temporary card
                    onCreationFinish?.(currentChartData);
                  });
                } else if (initialChartSettings) {
                  updateCustomReportChartSettings(form)?.then(() => {
                    setDefaultChartData(currentChartData);
                    setCardState(EditableReportCardStates.view);
                  });
                }
              },
            }}
          />
        ),
      }}
    >
      <CustomReportChart
        {...{
          customReportLaunchResult,
          chartData: currentChartData,
          chartSettings: requestedChartSettings,
        }}
      />
    </EditableReportCard>
  );
};

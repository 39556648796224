import { useMemo } from 'react';

import { CowsCopyReasonEnum } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { ButtonThemes, ButtonVariants } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { SkeletonBlock } from '~/shared/components/Skeleton/components/SkeletonBlock';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import {
  CowsCopyActiveKind,
  MOVE_COWS_ACTIVE_KEY_TAB_ITEMS,
  MOVE_COWS_REASON_TAB_ITEMS,
  MoveCowsKeyAccordion,
  useMoveCowsModal,
} from '~/features/cowsMovement';
import { useCowsCopyKeysQuery } from '~/features/cowsMovement/gql/queries/CowsCopyKeys.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { normalizeValueByAllowedList } from './helpers';
import styles from './index.module.scss';

const DATA_BLOCKED_MESSAGE_DICT: Record<
  CowsCopyReasonEnum,
  Record<CowsCopyActiveKind, string>
> = {
  [CowsCopyReasonEnum.Sell]: {
    [CowsCopyActiveKind.isActivated]:
      'У вас пока нет действующих ключей для продажи',
    [CowsCopyActiveKind.isArchived]:
      'У вас пока нет архивных ключей для продажи',
  },
  [CowsCopyReasonEnum.Move]: {
    [CowsCopyActiveKind.isActivated]:
      'У вас пока нет действующих ключей для перемещения на другую площадку',
    [CowsCopyActiveKind.isArchived]:
      'У вас пока нет архивных ключей для перемещения на другую площадку',
  },
};

const SKELETON_BLOCKS_AMOUNT = 3;
const SKELETON_BLOCK_HEIGHT = 56;

interface MoveCowsKeysSearchParams {
  cowsCopyReason: CowsCopyReasonEnum;
  cowsCopyActiveKind: CowsCopyActiveKind;
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/herd/cows/moveCowsKeys/'
)({
  component: MoveCowsKeysPage,
  validateSearch: ({
    cowsCopyReason,
    cowsCopyActiveKind,
  }: WithSearchParamsValidation<MoveCowsKeysSearchParams>) => ({
    cowsCopyReason: cowsCopyReason ?? CowsCopyReasonEnum.Sell,
    cowsCopyActiveKind: cowsCopyActiveKind ?? CowsCopyActiveKind.isActivated,
  }),
});

function MoveCowsKeysPage() {
  const { open: openMoveCowsModal } = useMoveCowsModal();

  const {
    cowsCopyReason: cowsCopyReasonParam,
    cowsCopyActiveKind: cowsCopyActiveKindParam,
    setCowsCopyReason,
    setCowsCopyActiveKind,
  } = useSearchParamsState<typeof Route>();

  const cowsCopyReason = useMemo(
    () =>
      normalizeValueByAllowedList({
        value: cowsCopyReasonParam,
        allowedValues: Object.values(CowsCopyReasonEnum),
        defaultValue: CowsCopyReasonEnum.Sell,
      }),
    [cowsCopyReasonParam]
  );

  const activeKind = useMemo(
    () =>
      normalizeValueByAllowedList({
        value: cowsCopyActiveKindParam,
        allowedValues: Object.values(CowsCopyActiveKind),
        defaultValue: CowsCopyActiveKind.isActivated,
      }),
    [cowsCopyActiveKindParam]
  );

  const queryVariables = useMemo(
    () => ({
      isActivated: activeKind === CowsCopyActiveKind.isArchived,
      reason: cowsCopyReason,
    }),
    [activeKind, cowsCopyReason]
  );

  const { data: cowsCopyKeyQuery, loading: isCowsCopyKeysLoading } =
    useCowsCopyKeysQuery({ variables: queryVariables });
  const { cowsCopyKeys } = cowsCopyKeyQuery ?? {};
  const hasCowsCopyKeys = !!cowsCopyKeys?.length;

  const getContent = () => {
    if (isCowsCopyKeysLoading) {
      return (
        <div className="flex flex-col gap-16">
          {R.range(0, SKELETON_BLOCKS_AMOUNT).map((_, index) => (
            <SkeletonBlock
              {...{
                key: index,
                height: SKELETON_BLOCK_HEIGHT,
                width: '100%',
                borderRadius: 'var(--border-radius-12)',
              }}
            />
          ))}
        </div>
      );
    }

    if (!hasCowsCopyKeys) {
      return (
        <div className={styles.blockedMessage}>
          <DataBlockedMessage
            {...{
              buttonProps: {
                variant: ButtonVariants.secondary,
                theme: ButtonThemes.accent,
                children: 'Перейти к формированию ключа',
                onPress: () => openMoveCowsModal(),
              },
              className: 'p-24',
              message: DATA_BLOCKED_MESSAGE_DICT[cowsCopyReason][activeKind],
            }}
          />
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-16">
        {cowsCopyKeys.map(cowsCopyKey => (
          <MoveCowsKeyAccordion
            {...{
              key: cowsCopyKey.key,
              cowsCopyKey,
              queryVariables,
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Список ключей перемещения',
          backLinkProps: {
            from: Route.fullPath,
            to: '..',
          },
        }}
      />
      <div
        className={clsx(panelStyles.largePanel, 'p-24 flex flex-col gap-24')}
      >
        <RadioGroup
          {...{
            variant: RadioGroupVariants.segmented,
            items: MOVE_COWS_REASON_TAB_ITEMS,
            value: cowsCopyReason,
            onValueChange: setCowsCopyReason,
            withFormContext: false,
            name: 'reason',
          }}
        />
        <RadioGroup
          {...{
            variant: RadioGroupVariants.chip,
            items: MOVE_COWS_ACTIVE_KEY_TAB_ITEMS,
            value: activeKind,
            onValueChange: setCowsCopyActiveKind,
            withFormContext: false,
            name: 'isActivated',
          }}
        />
        {getContent()}
      </div>
    </div>
  );
}

import React, { useRef } from 'react';

import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useConfirm } from '~/services/modals';

import { readSourceFieldFragment } from '~/entities/blueprintSourceFields';
import {
  updateCustomReportChartSettingsFragment,
  updateCustomReportDetailedFragment,
} from '~/entities/customReports';
import { CustomReportChartSettingsFragment } from '~/entities/customReports/gql/fragments/customReportChartSettings.graphql';
import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';

import { ValueConfigArrayItemFormType } from '~/features/customReportPivot';

import {
  CreateCustomReportChartSettingsMutation,
  useCreateCustomReportChartSettingsMutation,
} from '../gql/mutations/createCustomReportChartSettings.graphql';
import { useDeleteCustomReportChartSettingsMutation } from '../gql/mutations/deleteCustomReportChartSettings.graphql';
import { useUpdateCustomReportChartSettingsMutation } from '../gql/mutations/updateCustomReportChartSettings.graphql';
import { CustomReportChartSettingsFormType } from '../types';

interface UseCustomReportChartSettingsCRUDProps {
  /**
   * Custom report to take settings from
   */
  customReport: CustomReportDetailedFragment;
  /**
   * Chart settings to edit
   */
  chartSettings: CustomReportChartSettingsFragment | undefined;
}

/**
 * Hook for CRUD actions on a chartSetting
 */
export const useCustomReportChartSettingsCRUD = ({
  customReport,
  chartSettings,
}: UseCustomReportChartSettingsCRUDProps) => {
  const cardRef = useRef<React.ElementRef<'div'>>(null);

  // Chart settings create logic
  const [
    createCustomReportChartSettingsMutation,
    { loading: isCreateCustomReportChartSettingsLoading },
  ] = useCreateCustomReportChartSettingsMutation();

  const createCustomReportChartSettings = (
    form: CustomReportChartSettingsFormType
  ) =>
    createCustomReportChartSettingsMutation({
      variables: {
        input: {
          ...form,
          customReportID: customReport.id,
        },
      },
      update: R.juxt([
        () => {
          if (cardRef.current) {
            // We need to hide temporary card immediately,
            // cause we will render newly added card instead
            cardRef.current.style.display = 'none';
          }
        },
        updateCustomReportDetailedFragment<CreateCustomReportChartSettingsMutation>(
          customReport.id,
          (draft, { mutationData }) => {
            draft.chartsSettings.unshift(
              mutationData.createCustomReportChartSettings
            );
          }
        ),
      ]),
    });

  // Chart settings update logic
  const [
    updateCustomReportChartSettingsMutation,
    { loading: isUpdateCustomReportChartSettingsLoading },
  ] = useUpdateCustomReportChartSettingsMutation();

  const updateCustomReportChartSettings = (
    form: CustomReportChartSettingsFormType
  ) => {
    if (!chartSettings) {
      return undefined;
    }

    return updateCustomReportChartSettingsMutation({
      variables: {
        id: chartSettings.id,
        input: form,
      },
      optimisticResponse: {
        updateCustomReportChartSettings: null,
      },
      update: updateCustomReportChartSettingsFragment(
        chartSettings.id,
        (draft, { cache }) => {
          draft.kind = form.kind;
          draft.name = form.name;

          const denormalizeValueKey = (
            valueKey: ValueConfigArrayItemFormType
          ) => {
            const blueprintSourceField = readSourceFieldFragment(
              cache,
              valueKey.blueprintSourceFieldID
            );

            if (!blueprintSourceField) {
              return null;
            }

            return {
              __typename: 'CustomReportSettingsValue' as const,
              ...R.omit(['blueprintSourceFieldID'], valueKey),
              blueprintSourceField,
            };
          };

          // denormalize xAxis
          if (form.xAxis?.blueprintSourceFieldID) {
            const blueprintSourceField = readSourceFieldFragment(
              cache,
              form.xAxis?.blueprintSourceFieldID
            );
            if (blueprintSourceField) {
              draft.xAxis = {
                __typename: 'CustomReportChartFieldAxis',
                blueprintSourceField,
              };
            }
          } else if (form.xAxis?.valueKey) {
            const denormalizedValueKey = denormalizeValueKey(
              form.xAxis.valueKey
            );
            if (denormalizedValueKey) {
              draft.xAxis = {
                __typename: 'CustomReportChartValueAxis',
                valueKey: denormalizedValueKey,
              };
            }
          }

          // denormalize yAxes
          draft.yAxes = form.yAxes
            .map(yAxisConfig => {
              if (yAxisConfig.valueKey) {
                const denormalizedValueKey = denormalizeValueKey(
                  yAxisConfig.valueKey
                );
                if (!denormalizedValueKey) {
                  return null;
                }

                return {
                  __typename: 'CustomReportChartAggValueAxis' as const,
                  valueKey: denormalizedValueKey,
                  aggFormula: yAxisConfig.aggFormula,
                  withRightScale: yAxisConfig.withRightScale,
                };
              }

              return {
                __typename: 'CustomReportChartFieldValueAxis' as const,
                blueprintSourceFieldValue: {
                  __typename: 'CustomReportBlueprintSourceFieldValue' as const,
                  ...yAxisConfig.blueprintSourceFieldValue,
                },
                aggFormula: yAxisConfig.aggFormula,
                withRightScale: yAxisConfig.withRightScale,
              };
            })
            .filter(Boolean);
        }
      ),
    });
  };

  // Chart settings delete logic
  const [deleteCustomReportChartSettingsMutation] =
    useDeleteCustomReportChartSettingsMutation();
  const confirmDelete = useConfirm();
  const deleteCustomReportChartSettings = () => {
    if (!chartSettings) return;

    confirmDelete({
      title: `Удаление диаграммы`,
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить диаграмму{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {chartSettings.name}
            </Typography>
            ?
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    }).then(isConfirmed => {
      if (!isConfirmed) return;

      deleteCustomReportChartSettingsMutation({
        variables: {
          id: chartSettings.id,
        },
        optimisticResponse: { deleteCustomReportChartSettings: null },
        update: updateCustomReportDetailedFragment(customReport.id, draft => {
          const chartSettingsIndexToDelete = draft.chartsSettings.findIndex(
            settings => settings.id === chartSettings.id
          );
          if (chartSettingsIndexToDelete >= 0) {
            draft.chartsSettings.splice(chartSettingsIndexToDelete, 1);
          }
        }),
      });
    });
  };

  return {
    cardRef,

    createCustomReportChartSettings,
    isCreateCustomReportChartSettingsLoading,

    updateCustomReportChartSettings,
    isUpdateCustomReportChartSettingsLoading,

    deleteCustomReportChartSettings,
  };
};

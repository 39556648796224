import React, { ReactNode } from 'react';

import clsx from 'clsx';
import R from 'ramda';
import { match } from 'ts-pattern';

import {
  Button,
  ButtonPropsWithoutRef,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { useAccordion } from '~/shared/hooks/useAccordion';
import { useControllableState } from '~/shared/hooks/useControllableState';

import styles from './index.module.scss';

/**
 * Possible report card states
 */
export enum EditableReportCardStates {
  create = 'create',
  update = 'update',
  view = 'view',
}

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Props for submit button (create or update)
   */
  submitButtonProps?: Partial<ButtonPropsWithoutRef>;
  /**
   * Props for settings button
   */
  settingsButtonProps?: Partial<ButtonPropsWithoutRef>;

  /**
   * Card editing state
   */
  cardState?: EditableReportCardStates;
  /**
   * Called, when card state is changed by pressing buttons
   */
  onCardStateChange?: (newCardState: EditableReportCardStates) => void;
  /**
   * Default value for card state
   */
  defaultCardState?: EditableReportCardStates;

  /**
   * Title for the card in view state
   */
  title?: string;
  /**
   * Title for the card is in editing state
   */
  updatingTitle?: string;
  /**
   * Title for the card is in creation state
   */
  creatingTitle?: string;

  /**
   * If true, cancel button is rendered in creation state (default - true)
   */
  shouldRenderCancelButtonForCreate?: boolean;
  /**
   * If passed, renders a delete button and call this handler on press
   */
  onDelete?: () => void;

  /**
   * Render prop for rendering editing controls for create and editing states
   */
  renderEditingContent: () => ReactNode;
  /**
   * Renders main children, based on card editing state
   */
  children: ReactNode | ((isEditing: boolean) => ReactNode);
}

export const EditableReportCard = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      submitButtonProps,
      settingsButtonProps,

      cardState: cardStateProp,
      onCardStateChange,
      defaultCardState = EditableReportCardStates.view,

      title = '',
      updatingTitle = title,
      creatingTitle = updatingTitle,

      shouldRenderCancelButtonForCreate = true,
      onDelete,

      renderEditingContent,
      children,
    }: Props,
    ref
  ) => {
    const [cardState, setCardState] = useControllableState(
      cardStateProp,
      onCardStateChange,
      defaultCardState
    );

    const isCreating = cardState === EditableReportCardStates.create;
    const isUpdating = cardState === EditableReportCardStates.update;
    const isEditing = isCreating || isUpdating;
    const isViewing = cardState === EditableReportCardStates.view;

    const {
      shouldRenderContent,

      childrenContainerRef,

      childrenWrapperProps,
    } = useAccordion({
      isOpen: isEditing,
    });

    return (
      <div
        {...{
          ref,
          className: clsx(
            isEditing ? styles.editingRoot : styles.viewingRoot,
            className
          ),
        }}
      >
        <div className={styles.header}>
          <div className="flex items-center">
            <Typography
              {...{
                className: 'mr-24',
                variant: TypographyVariants.bodyLargeStrong,
              }}
            >
              {match(cardState)
                .with(EditableReportCardStates.create, R.always(creatingTitle))
                .with(EditableReportCardStates.update, R.always(updatingTitle))
                .with(EditableReportCardStates.view, R.always(title))
                .exhaustive()}
            </Typography>
            <div className="flex gap-8 ml-a">
              {isViewing && (
                <>
                  <Button
                    {...{
                      variant: ButtonVariants.secondary,
                      size: ButtonSizes.small24,
                      iconVariant: IconVariants.settings,
                      onPress: () =>
                        setCardState(EditableReportCardStates.update),
                      ...settingsButtonProps,
                    }}
                  />
                  {onDelete && (
                    <Button
                      {...{
                        variant: ButtonVariants.secondary,
                        size: ButtonSizes.small24,
                        iconVariant: IconVariants.delete,
                        onPress: onDelete,
                      }}
                    />
                  )}
                </>
              )}

              {((shouldRenderCancelButtonForCreate && isCreating) ||
                isUpdating) && (
                <Button
                  {...{
                    variant: ButtonVariants.secondary,
                    size: ButtonSizes.small24,
                    onPress: () => setCardState(EditableReportCardStates.view),
                  }}
                >
                  Отмена
                </Button>
              )}

              {isEditing && (
                <Button
                  {...{
                    size: ButtonSizes.small24,
                    type: 'submit',
                    ...submitButtonProps,
                  }}
                >
                  {isCreating ? 'Создать' : 'Сохранить'}
                </Button>
              )}
            </div>
          </div>
          <div
            {...{
              ...childrenWrapperProps,
              className: styles.accordionChildren,
            }}
          >
            <div ref={childrenContainerRef}>
              {shouldRenderContent && (
                <div className="my-24 container-inline-size">
                  {renderEditingContent()}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.children}>
          {typeof children === 'function' ? children(isEditing) : children}
        </div>
      </div>
    );
  }
);

import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  getSingleSkeletonPlaceholder,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';

import {
  Callout,
  getNotificationPropsFromGQLError,
  NotificationVariants,
} from '~/services/notifications';

import { useBlueprintSourceFields } from '~/entities/blueprintSourceFields';
import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';
import { useUpdateCustomReportMutation } from '~/entities/customReports/gql/mutations/updateCustomReport.graphql';

import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';
import {
  EditableReportCard,
  EditableReportCardStates,
} from '~/features/customReportsBuilder';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID } from '../../constants';
import { useCalculateCustomReportPivotTableMutation } from '../../gql/mutations/calculateCustomReportPivotTable.graphql';
import {
  getValueColumnName,
  isDirtyPivotTable,
  isEmptyPivotTable,
  isValidPivotTable,
  mapCustomReportSettingsToForm,
} from '../../helpers';
import { CustomReportPivotSettingsForm } from '../CustomReportPivotSettingsForm';
import { CustomReportPivotTable } from '../CustomReportPivotTable';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to take settings from
   */
  customReport: CustomReportDetailedFragment | SkeletonPlaceholder;
  /**
   * Launch result to render pivot table
   */
  customReportLaunchResult:
    | CustomReportLaunchResultFragment
    | SkeletonPlaceholder;
}

export const CustomReportPivotCard: React.FC<Props> = ({
  className,
  customReport,
  customReportLaunchResult,
}) => {
  const { isLoading } = useSkeletonContext();

  const isEmptySettings =
    !customReport.settings?.rows.length &&
    !customReport.settings?.columns.length &&
    !customReport.settings?.values.length;

  const [cardState, setCardState] = useState(
    !isLoading && isEmptySettings
      ? EditableReportCardStates.create
      : EditableReportCardStates.view
  );

  const [updateCustomReport, { loading: isUpdateCustomReportLoading }] =
    useUpdateCustomReportMutation({
      onCompleted: () => {
        setCardState(EditableReportCardStates.view);
      },
    });

  const abortControllerRef = useRef<AbortController>();

  const [
    calculateCustomReportPivotTable,
    {
      error: calculateCustomReportPivotTableError,
      reset: resetCalculateCustomReportPivotTableMutation,
    },
  ] = useCalculateCustomReportPivotTableMutation();
  const [requestedCustomReportSettings, setRequestedCustomReportSettings] =
    useState(mapCustomReportSettingsToForm(customReport));

  const initialPivotData =
    customReportLaunchResult.pivotTable ?? getSingleSkeletonPlaceholder();
  const [currentPivotData, setCurrentPivotData] = useState(initialPivotData);

  // Update pivot data from skeleton, after we loaded launch result
  // or when update report from modal window
  useEffect(() => {
    if (!isSkeletonPlaceholder(customReportLaunchResult)) {
      setCurrentPivotData(initialPivotData);
    }
  }, [customReportLaunchResult]);

  // Update custom report form values, after we loaded custom report
  useEffect(() => {
    if (!isSkeletonPlaceholder(customReport)) {
      setRequestedCustomReportSettings(
        mapCustomReportSettingsToForm(customReport)
      );
    }
  }, [customReport]);

  // Set creation state, if we loaded empty blueprint
  useEffect(() => {
    if (isEmptySettings && !isLoading) {
      setCardState(EditableReportCardStates.create);
    }
  }, [isEmptySettings, isLoading]);

  const firstValueConfig = customReport.settings?.values.at(0);

  const { getSourceFieldById } = useBlueprintSourceFields(
    customReportLaunchResult.blueprintLaunchResult?.columnSourceFields ?? []
  );

  const tableTitle =
    customReport.settings?.values.length === 1 && firstValueConfig
      ? getValueColumnName(firstValueConfig, getSourceFieldById, false)
      : 'Сводная таблица';

  return (
    <EditableReportCard
      {...{
        title: tableTitle,
        updatingTitle: 'Настройки сводной таблицы',
        creatingTitle: 'Создание сводной таблицы',
        shouldRenderCancelButtonForCreate: false,
        className,
        cardState,
        onCardStateChange: newCardState => {
          // Cancel is pressed
          if (newCardState === EditableReportCardStates.view) {
            abortControllerRef.current?.abort();
            resetCalculateCustomReportPivotTableMutation();
            setRequestedCustomReportSettings(
              mapCustomReportSettingsToForm(customReport)
            );
            setCurrentPivotData(initialPivotData);
          }
          setCardState(newCardState);
        },
        submitButtonProps: {
          form: CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID,
          isLoading: isUpdateCustomReportLoading,
        },
        settingsButtonProps: {
          isDisabled: isDirtyPivotTable(currentPivotData),
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(customReport) &&
          !isSkeletonPlaceholder(customReportLaunchResult) &&
          !isSkeletonPlaceholder(currentPivotData) &&
          !isDirtyPivotTable(currentPivotData) && (
            <CustomReportPivotSettingsForm
              {...{
                updateCustomReport,
                onFormSettingsChange: settingsInput => {
                  abortControllerRef.current?.abort();

                  abortControllerRef.current = new AbortController();

                  calculateCustomReportPivotTable({
                    variables: {
                      customReportHashID: customReportLaunchResult.hashID,
                      settingsInput,
                    },
                    context: {
                      fetchOptions: {
                        signal: abortControllerRef.current.signal,
                      },
                      shouldSkipErrorNotifications: true,
                    },
                  })
                    .then(({ data }) => {
                      if (data?.calculateCustomReportPivotTable) {
                        setRequestedCustomReportSettings(settingsInput);
                        setCurrentPivotData(
                          data.calculateCustomReportPivotTable
                        );
                      }
                    })
                    .catch(() => {});
                },
                customReport,
                blueprintLaunchResult:
                  customReportLaunchResult.blueprintLaunchResult,
                pivotData: isValidPivotTable(currentPivotData)
                  ? currentPivotData
                  : {
                      __typename: 'CustomReportPivotTable',
                      rows: [],
                      columns: [],
                    },
              }}
            />
          ),
      }}
    >
      {isEditing => {
        if (calculateCustomReportPivotTableError) {
          return (
            <div className="flex flex-col gap-16">
              {calculateCustomReportPivotTableError.graphQLErrors.map(
                (error, index) => (
                  <Callout
                    {...{
                      key: `${error.message}__${index}`,
                      variant: NotificationVariants.error,
                      ...getNotificationPropsFromGQLError(error),
                    }}
                  />
                )
              )}
            </div>
          );
        }

        if (
          isEmptyPivotTable(currentPivotData) ||
          isDirtyPivotTable(currentPivotData)
        ) {
          return (
            <div className={clsx('p-24', panelStyles.borderedPanel)}>
              <DataBlockedMessage
                {...{
                  className: 'p-24',
                  message: isEmptyPivotTable(currentPivotData)
                    ? 'Нет данных для отображения'
                    : 'Недостаточно данных для отображения, проверьте настройки списка',
                }}
              />
            </div>
          );
        }

        return (
          <CustomReportPivotTable
            {...{
              isEditing,
              requestedCustomReportSettings,
              pivotData: currentPivotData,
            }}
          />
        );
      }}
    </EditableReportCard>
  );
};

import React, { useMemo } from 'react';

import {
  AnalyticalMetric,
  AnalyticalMetricEnum,
  CrData,
  HdrData,
  PrData,
} from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import {
  DateFormats,
  formatDateRange,
  sortByDate,
} from '~/shared/helpers/date';
import { formatWithPercent } from '~/shared/helpers/number';

import { DashboardCard, METRIC_CONFIGS } from '~/features/analytics';
import { BarChart, ChartCard } from '~/features/charts';

import { REPRODUCTION_CHART_CONFIGS } from '../../constants';
import { ReproductionChartTypes } from '../../types';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Selected chart types to display;
   */
  chartTypes?: ReproductionChartTypes[];
  /**
   * Heat detection rate chart data
   */
  hdrData: HdrData[];
  /**
   * Pregnancy rate chart data
   */
  prData: PrData[];
  /**
   * Conception rate chart data
   */
  crData: CrData[];
  /**
   * Metric names to display (used to display loading cards, before actual metrics are loaded)
   */
  metricsToDisplay: AnalyticalMetricEnum[];
  /**
   * Metric values
   */
  metrics: (AnalyticalMetric | undefined)[];
  /**
   * Is chart loading
   */
  isLoading?: boolean;
}

const REPRODUCTION_CHART_HEIGHT_PX = 359;

const getDataItemXValue = ({
  since,
  till,
}: {
  since?: string;
  till?: string;
}) => formatDateRange(since, till, DateFormats.dayAndMonthTwoDigit);

export const ReproductionChartBlock: React.FC<Props> = ({
  className,

  chartTypes = [],
  hdrData,
  prData,
  crData,

  metricsToDisplay,
  metrics = [],

  isLoading,
}) => {
  const { datasets, labels } = useMemo(() => {
    const charts = {
      [ReproductionChartTypes.heatDetectionRateChart]: {
        ...REPRODUCTION_CHART_CONFIGS[
          ReproductionChartTypes.heatDetectionRateChart
        ],
        data: hdrData.map(dataItem => ({
          x: getDataItemXValue(dataItem),
          y: dataItem.hdr,
        })),
      },
      [ReproductionChartTypes.pregnancyRateChart]: {
        ...REPRODUCTION_CHART_CONFIGS[
          ReproductionChartTypes.pregnancyRateChart
        ],
        data: prData.map(dataItem => ({
          x: getDataItemXValue(dataItem),
          y: dataItem.pr,
        })),
      },
      [ReproductionChartTypes.conceptionRateChart]: {
        ...REPRODUCTION_CHART_CONFIGS[
          ReproductionChartTypes.conceptionRateChart
        ],
        data: crData.map(dataItem => ({
          x: getDataItemXValue(dataItem),
          y: dataItem.cr,
        })),
      },
    };

    return {
      datasets: Object.entries(charts)
        .filter(([key]) => chartTypes.includes(key))
        .map(([, config]) => config),
      labels: sortByDate(
        R.uniqBy(R.prop('since'), [...hdrData, ...prData, ...crData]),
        R.prop('since')
      ).map(getDataItemXValue),
    };
  }, [chartTypes, hdrData, prData, crData]);

  return (
    <div className={clsx(className, styles.panel)}>
      <ChartCard
        {...{
          className: styles.chart,
          datasets,
          title: 'График по показателям',
          isLoading,
        }}
      >
        <BarChart
          {...{
            legendClassName: 'my-16',
            height: REPRODUCTION_CHART_HEIGHT_PX,
            datasets,
            labels,
            isStackedX: true,
            isPercents: true,
          }}
        />
      </ChartCard>
      {metricsToDisplay.map(metricName => (
        <DashboardCard
          {...{
            key: metricName,
            metricName,
            formatValue: formatWithPercent,
            metric: metrics.find(m => m?.metric === metricName),
            isLoading,
            isBlocked:
              !isLoading &&
              match(METRIC_CONFIGS[metricName].dataField)
                .with(P.string.includes('hdr'), R.always(!hdrData.length))
                .with(P.string.includes('pr'), R.always(!prData.length))
                .with(P.string.includes('cr'), R.always(!crData.length))
                .otherwise(R.always(true)),
            cardLinkProps: {
              to: '/$companyId/user/analytics/reproduction/$reproductionTableUrl',
              params: prev => ({
                ...prev,
                reproductionTableUrl:
                  METRIC_CONFIGS[metricName].detailDataPageUrl,
              }),
              search: true,
            },
          }}
        />
      ))}
    </div>
  );
};

import React, { useMemo } from 'react';

import { CustomReportChartKindEnum } from '@graphql-types';
import { ChartOptions, LinearScale } from 'chart.js';
import R from 'ramda';
import { match } from 'ts-pattern';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatNumber, formatWithPercent } from '~/shared/helpers/number';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { formatSourceFieldValue } from '~/entities/customReports';

import {
  BarChart,
  CHART_DATASET_COLORS_ITERATOR,
  CHART_DATASET_HOVER_COLORS_ITERATOR,
  CHART_DATASET_PRESSED_COLORS_ITERATOR,
  CHART_DATASET_SKIPPED_COLORS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
  getScaleOptions,
  LINEAR_Y_SCALE_OPTIONS,
  LineChart,
  PERCENT_LINEAR_Y_SCALE_OPTIONS,
  PieChart,
  ReactChartProps,
  ScatterChart,
} from '~/features/charts';
import { isCustomReportChart } from '~/features/customReportCharts/helpers';
import { CustomReportChartFragment } from '~/features/customReportLaunch/gql/fragments/customReportChart.graphql';
import { CustomReportChartMultipleValuesFragment } from '~/features/customReportLaunch/gql/fragments/customReportChartMultipleValues.graphql';
import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';

import { useCustomReportChartAxes } from '../../../../hooks';
import { CustomReportChartSettingsFormType } from '../../../../types';

interface Props {
  /**
   * Launch result to get blueprint source field values from nested columns that can be added to axes
   */
  customReportLaunchResult: CustomReportLaunchResultFragment;
  /**
   * Chart data to render
   */
  chartData:
    | CustomReportChartFragment
    | CustomReportChartMultipleValuesFragment;
  /**
   * Settings, used to configure the chart
   */
  chartSettings: Omit<CustomReportChartSettingsFormType, 'name'>;
}

const CHART_HEIGHT_PX = 376;

export const CustomReportValidChart: React.FC<Props> = ({
  customReportLaunchResult,
  chartData,
  chartSettings,
}) => {
  const isPercents =
    chartSettings.kind === CustomReportChartKindEnum.PercentBar;
  const isStackedBar =
    chartSettings.kind === CustomReportChartKindEnum.StackedBar;
  const isPie = chartSettings.kind === CustomReportChartKindEnum.Pie;

  const isStackedChart = isStackedBar || isPercents;

  const { launchHeaderColumnName, getXAxisName, getYAxisName } =
    useCustomReportChartAxes({
      customReportLaunchResult,
    });
  const xAxisName = getXAxisName(chartSettings.xAxis);

  const xAxisLabels = useMemo(
    () =>
      chartData.xAxisLabels.map(label => {
        const formattedValue = formatSourceFieldValue(label);
        return formattedValue === '' ? 'Нет значения' : formattedValue;
      }),
    [chartData]
  );

  const datasets = useMemo(() => {
    // This is an array of datasets with arrays of sorted by x coordinate arrays of values for given x,
    const yAxisDatasets = isCustomReportChart(chartData)
      ? chartData.yAxisDatasets.map(dataset =>
          dataset.map(dataPoint => [dataPoint.value ?? NaN])
        )
      : chartData.yAxisMultiValueDatasets.map(dataset =>
          dataset.map(dataPoints =>
            dataPoints.map(dataPoint => dataPoint.value ?? NaN)
          )
        );

    const yAxisDatasetsSummedYValues = yAxisDatasets.map(dataset =>
      dataset.map(dataPoints =>
        R.sum(
          dataPoints.map(pointValue =>
            !pointValue || Number.isNaN(pointValue) ? 0 : pointValue
          )
        )
      )
    );
    const dataSumsByDataIndex = isPercents
      ? yAxisDatasetsSummedYValues.at(0)?.map((dataPoint, dataIndex) => {
          return R.sum(
            yAxisDatasetsSummedYValues.map(dataPoints => dataPoints[dataIndex])
          );
        })
      : undefined;

    return yAxisDatasets.map((dataset, datasetIndex) => {
      const axisSettings = chartSettings.yAxes.at(datasetIndex);

      const fullData = dataset.flatMap((dataPoints, xAxisLabelIndex) =>
        dataPoints.map(dataPoint => ({
          x: xAxisLabels[xAxisLabelIndex],
          y: dataPoint,
        }))
      );

      const dataSum = R.sum(
        fullData.map(dataPoint => {
          const yValue = dataPoint.y;
          return !yValue || Number.isNaN(yValue) ? 0 : yValue;
        })
      );

      return {
        key: datasetIndex.toString(),
        label: getYAxisName(chartSettings.yAxes.at(datasetIndex), false),
        color: CHART_DATASET_COLORS_ITERATOR.next(!datasetIndex).value,
        hoverColor:
          CHART_DATASET_HOVER_COLORS_ITERATOR.next(!datasetIndex).value,
        pressedColor:
          CHART_DATASET_PRESSED_COLORS_ITERATOR.next(!datasetIndex).value,
        skippedColor:
          CHART_DATASET_SKIPPED_COLORS_ITERATOR.next(!datasetIndex).value,
        yAxisID: axisSettings?.withRightScale ? 'rightY' : 'leftY',
        isTogglable: !isPie,
        originalData: fullData,
        data: !isPercents
          ? fullData
          : fullData.map((dataPoint, dataIndex) => {
              if (Number.isNaN(dataPoint.y)) {
                return dataPoint;
              }
              return {
                ...dataPoint,
                y:
                  (dataPoint.y / (dataSumsByDataIndex?.at(dataIndex) ?? 1)) *
                  100,
              };
            }),
        dataSum,
        dataSumsByDataIndex,
      };
    });
  }, [chartData, chartSettings, getYAxisName]);

  const shouldDisplayLeftYAxis = chartSettings.yAxes.some(
    R.complement(R.prop('withRightScale'))
  );
  const shouldDisplayRightYAxis = chartSettings.yAxes.some(
    R.prop('withRightScale')
  );

  const yScaleOptions = isPercents
    ? PERCENT_LINEAR_Y_SCALE_OPTIONS
    : LINEAR_Y_SCALE_OPTIONS;

  const commonChartOptions = {
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: getXAxisName(chartSettings.xAxis),
        },
      },
      y: {
        // Hide default y scale, cause we have custom left and right scales
        display: false,
      },
      ...wrapConditionalObjectElement(
        shouldDisplayLeftYAxis && {
          leftY: getScaleOptions(yScaleOptions, {
            display: 'auto',
            stacked: isStackedChart,
          }),
        }
      ),
      ...wrapConditionalObjectElement(
        shouldDisplayRightYAxis && {
          rightY: getScaleOptions(yScaleOptions, {
            grid: {
              // We should display grid here, only if we don't display the left axis
              display: ((context: { scale: LinearScale }) => {
                // eslint-disable-next-line no-underscore-dangle -- access private chart.js scale method
                return !(context.scale.chart.scales.leftY as any)?._isVisible();
              }) as any, // Chart.js doesn't have typings for passing callback here, but it works
            },
            display: 'auto',
            position: 'right',
            stacked: isStackedChart,
          }),
        }
      ),
    },
    plugins: {
      zoom: {
        limits: {
          x: {
            min: 'original',
            max: 'original',
            minRange: CHART_X_SCALE_MIN_RANGE,
          },
          leftY: {
            min: 'original',
            max: 'original',
            minRange: CHART_Y_SCALE_MIN_RANGE,
          },
          rightY: {
            min: 'original',
            max: 'original',
            minRange: CHART_Y_SCALE_MIN_RANGE,
          },
        },
        zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
        pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
      },
    },
  } satisfies ChartOptions;

  const renderTooltip: ReactChartProps['renderTooltip'] = dataPoints => {
    return (
      <div>
        <Typography
          {...{
            variant: TypographyVariants.descriptionLarge,
            tag: 'h4',
            className: 'mb-8',
          }}
        >
          {xAxisName}
          &nbsp;
          {R.uniq(
            dataPoints.map(point =>
              formatSourceFieldValue(chartData.xAxisLabels[point.dataIndex])
            )
          ).join(', ')}
        </Typography>
        <ul className="grid gap-4">
          {dataPoints.map(point => {
            const currentChartConfig = datasets.at(point.datasetIndex);
            if (!currentChartConfig) return null;

            let textToRender = point.formattedValue;
            if (typeof (point.raw as any)?.y === 'number') {
              const originalPoint =
                currentChartConfig.originalData[point.dataIndex];

              textToRender = formatNumber(originalPoint.y);

              if (isPie || isPercents) {
                const dataSum = isPercents
                  ? currentChartConfig.dataSumsByDataIndex?.at(point.dataIndex)
                  : currentChartConfig.dataSum;

                const percentValue = (originalPoint.y / (dataSum ?? 1)) * 100;
                textToRender = `${textToRender} (${formatWithPercent(
                  percentValue
                )})`;
              }
            }

            return (
              <Typography
                {...{
                  tag: 'li',
                  variant: TypographyVariants.descriptionLarge,
                  key: `${point.datasetIndex}__${point.dataIndex}`,
                  className: 'flex gap-8 items-center',
                }}
              >
                <ColoredDot
                  size={ColoredDotSizes.small10}
                  color={
                    isPie
                      ? point.element.options.backgroundColor
                      : currentChartConfig.color
                  }
                />
                {textToRender}
              </Typography>
            );
          })}
        </ul>
      </div>
    );
  };

  const commonChartProps = {
    datasets,
    legendTitle: isPie ? xAxisName : launchHeaderColumnName,
    labels: xAxisLabels,
    legendClassName: 'mb-32',
    height: CHART_HEIGHT_PX,
    renderTooltip,
  };

  return match(chartSettings.kind)
    .with(
      CustomReportChartKindEnum.Bar,
      CustomReportChartKindEnum.StackedBar,
      CustomReportChartKindEnum.PercentBar,
      () => {
        return (
          <BarChart
            {...{
              ...commonChartProps,
              chartOptions: {
                interaction: {
                  mode: 'point',
                },
                ...commonChartOptions,
              },
              isStacked: isStackedChart,
              isPercents,
            }}
          />
        );
      }
    )
    .with(CustomReportChartKindEnum.Scatter, () => {
      return (
        <ScatterChart
          {...{
            ...commonChartProps,
            chartOptions: commonChartOptions,
          }}
        />
      );
    })
    .with(CustomReportChartKindEnum.Line, () => {
      return (
        <LineChart
          {...{
            ...commonChartProps,
            chartOptions: commonChartOptions,
          }}
        />
      );
    })
    .with(CustomReportChartKindEnum.Pie, () => {
      return (
        <PieChart
          {...{
            ...commonChartProps,
            legendConfigs: xAxisLabels.map((label, labelIndex) => ({
              key: `${label}__${labelIndex}`,
              label,
              color: CHART_DATASET_COLORS_ITERATOR.next(!labelIndex).value,
            })),
            chartOptions: {
              plugins: {
                datalabels: {
                  formatter: (value, context) => {
                    const { dataIndex, datasetIndex } = context;
                    const currentChartConfig = datasets[datasetIndex];
                    const percentValue =
                      (value / currentChartConfig.dataSum) * 100;
                    return `${xAxisLabels[dataIndex]}: ${formatWithPercent(
                      percentValue
                    )}`;
                  },
                },
              },
            },
          }}
        />
      );
    })
    .exhaustive();
};

import React from 'react';

import clsx from 'clsx';

import { Form } from '~/services/forms';

import { useBlueprintSourceFields } from '~/entities/blueprintSourceFields';

import { EditableSettingsArrayCard } from '~/features/customReportsBuilder';

import contentGriStyles from '~/styles/modules/contentGrid.module.scss';

import { CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID } from '../../constants';
import { getCustomReportValueConfigKey } from '../../helpers';
import { RowOrColumnConfigArrayItemCard } from './components/RowOrColumnConfigArrayItemCard';
import { ValueConfigArrayItemCard } from './components/ValueConfigArrayItemCard';
import { useCustomReportPivotSettingsForm } from './hooks/useCustomReportPivotSettingsForm';
import { CustomReportPivotSettingsFormProps } from './types';

const MAX_ROWS_COUNT = 2;
const MAX_COLUMNS_COUNT = 1;
const MAX_VALUES_WITH_SELECTED_COLUMN_COUNT = 1;

const ADDING_DISABLED_TOOLTIP = 'Добавление недоступно';

export const CustomReportPivotSettingsForm: React.FC<
  CustomReportPivotSettingsFormProps
> = props => {
  const { className, pivotData, blueprintLaunchResult } = props;

  const {
    availableSourceFields,

    formContext,
    handleSubmit,

    availableForAddingValueConfigs,

    rowConfigFieldArrayItems,
    addToRowConfigsArray,
    removeFromRowConfigsArray,

    columnConfigFieldArrayItems,
    addToColumnConfigsArray,
    removeFromColumnConfigsArray,

    valueConfigFieldArrayItems,
    addToValueConfigsArray,
    removeFromValueConfigsArray,
  } = useCustomReportPivotSettingsForm(props);

  const useBlueprintSourceFieldsInterface = useBlueprintSourceFields(
    availableSourceFields
  );

  const blueprintSourceFieldMenuItems = availableSourceFields.map(
    sourceField => ({
      key: sourceField.id,
      content: sourceField.name,
    })
  );

  const availableForRowOrColumnSourceFieldMenuItems =
    blueprintSourceFieldMenuItems.filter(
      item =>
        !rowConfigFieldArrayItems.some(
          config => config.blueprintSourceFieldID === item.key
        ) &&
        !columnConfigFieldArrayItems.some(
          config => config.blueprintSourceFieldID === item.key
        )
    );

  const availableForValueSourceFieldMenuItems =
    blueprintSourceFieldMenuItems.filter(
      item => !!availableForAddingValueConfigs[item.key]
    );

  const isAddingRowsDisabled =
    !availableForRowOrColumnSourceFieldMenuItems.length ||
    rowConfigFieldArrayItems.length >= MAX_ROWS_COUNT;

  const isAddingColumnsDisabled =
    !availableForRowOrColumnSourceFieldMenuItems.length ||
    columnConfigFieldArrayItems.length >= MAX_COLUMNS_COUNT ||
    valueConfigFieldArrayItems.length > MAX_VALUES_WITH_SELECTED_COLUMN_COUNT;

  const isAddingValuesDisabled =
    !availableForValueSourceFieldMenuItems.length ||
    (columnConfigFieldArrayItems.length === MAX_COLUMNS_COUNT &&
      valueConfigFieldArrayItems.length >=
        MAX_VALUES_WITH_SELECTED_COLUMN_COUNT);

  return (
    <Form
      {...{
        className: clsx(
          'items-start',
          contentGriStyles.autoGridStaticItems,
          className
        ),
        formContext,
        id: CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID,
        onSubmit: formContext.handleSubmit(handleSubmit),
      }}
    >
      <EditableSettingsArrayCard<
        (typeof availableForRowOrColumnSourceFieldMenuItems)[number]
      >
        {...{
          title: 'Строки',
          noChildrenMessage: 'Список строк пока пуст',
          withChildrenCount: true,
          contextMenuButtonProps: {
            items: availableForRowOrColumnSourceFieldMenuItems,
            tooltip: isAddingRowsDisabled ? ADDING_DISABLED_TOOLTIP : undefined,
            isDisabled: isAddingRowsDisabled,
            menuProps: {
              onItemPress: item => addToRowConfigsArray(item.key),
            },
          },
        }}
      >
        {rowConfigFieldArrayItems.map((rowConfig, index) => (
          <RowOrColumnConfigArrayItemCard
            {...{
              key: rowConfig.blueprintSourceFieldID,
              isRow: true,
              fieldPrefix: `rows.${index}.`,
              rowOrColumnConfig: rowConfig,
              useBlueprintSourceFieldsInterface,
              onDelete: () => removeFromRowConfigsArray(index),
              valueConfigFieldArrayItems,
              blueprintLaunchResult,
              pivotData,
            }}
          />
        ))}
      </EditableSettingsArrayCard>

      <EditableSettingsArrayCard<
        (typeof availableForRowOrColumnSourceFieldMenuItems)[number]
      >
        {...{
          title: 'Столбцы',
          noChildrenMessage: 'Список столбцов пока пуст',
          withChildrenCount: true,
          contextMenuButtonProps: {
            items: availableForRowOrColumnSourceFieldMenuItems,
            isDisabled: isAddingColumnsDisabled,
            tooltip: isAddingColumnsDisabled
              ? ADDING_DISABLED_TOOLTIP
              : undefined,
            menuProps: {
              onItemPress: item => addToColumnConfigsArray(item.key),
            },
          },
        }}
      >
        {columnConfigFieldArrayItems.map((columnConfig, index) => (
          <RowOrColumnConfigArrayItemCard
            {...{
              key: columnConfig.blueprintSourceFieldID,
              isRow: false,
              fieldPrefix: `columns.${index}.`,
              rowOrColumnConfig: columnConfig,
              useBlueprintSourceFieldsInterface,
              onDelete: () => removeFromColumnConfigsArray(index),
              valueConfigFieldArrayItems,
              blueprintLaunchResult,
              pivotData,
            }}
          />
        ))}
      </EditableSettingsArrayCard>

      <EditableSettingsArrayCard<
        (typeof availableForValueSourceFieldMenuItems)[number]
      >
        {...{
          title: 'Значения',
          noChildrenMessage: 'Список значений пока пуст',
          withChildrenCount: true,
          contextMenuButtonProps: {
            items: availableForValueSourceFieldMenuItems,
            isDisabled: isAddingValuesDisabled,
            tooltip: isAddingValuesDisabled
              ? ADDING_DISABLED_TOOLTIP
              : undefined,
            menuProps: {
              onItemPress: item => addToValueConfigsArray(item.key),
            },
          },
        }}
      >
        {valueConfigFieldArrayItems.map((valueConfig, index) => (
          <ValueConfigArrayItemCard
            {...{
              key: getCustomReportValueConfigKey(valueConfig),
              fieldPrefix: `values.${index}.`,
              valueConfig,
              useBlueprintSourceFieldsInterface,
              availableForAddingToSourceFieldValueConfigs:
                availableForAddingValueConfigs[
                  valueConfig.blueprintSourceFieldID
                ] ?? {},
              onDelete: () => removeFromValueConfigsArray(index),
            }}
          />
        ))}
      </EditableSettingsArrayCard>
    </Form>
  );
};
